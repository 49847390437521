import Layout from '@components/layout/Layout';
import Container from '@components/ui/container/Container';
import Footer from '@components/ui/footer/Footer';
import Header from '@components/ui/header/Header';
import Specialization from '@components/ui/specialization/Specialization';
import TitleBar from '@components/ui/title-bar/TitleBar';
import LanguageProvider from '@lib/language.provider';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const SpecjalizacjePage = ({ pageContext, location }) => {
	i18n.activate(pageContext.language);

	const data = [
		{
			label: t`Telekomunikacja`,
			description: t`Ponad 20 lat obsługi prawnej jednego z największych przedsiębiorców telekomunikacyjnych w kraju`,
			link: '/specjalizacje/telekomunikacja'
		},
		{
			label: t`Fuzje i przejęcia`,
			description: t`Ponad 40 zrealizowanych transakcji M&A`,
			link: '/specjalizacje/fuzje-i-przejecia'
		},

		{
			label: t`Kontrakty cywilne i handlowe`,
			description: t`Ponad 50 umów na wdrożenie oprogramowania`,
			link: '/specjalizacje/kontrakty-cywilne-i-handlowe'
		},
		{
			label: t`Spółki i prawo korporacyjne`,
			description: t`Procesy związane z zakładaniem spółek oraz ich restrukturyzacją stanowią jedną z najistotniejszych dziedzin naszej działalności`,
			link: '/specjalizacje/spolki-i-prawo-korporacyjne'
		},
		{
			label: t`IT i media`,
			description: t`Świadczymy usługi doradztwa prawnego w zakresie szeroko rozumianej ochrony praw własności intelektualnej`,
			link: '/specjalizacje/it-i-media'
		},
		{
			label: t`Prawo konsumenckie`,
			description: t`Dzięki zdobytemu doświadczeniu i ugruntowanej wiedzy potrafimy starannie ułożyć relacje handlowe z konsumentami`,
			link: '/specjalizacje/prawo-konsumenckie'
		},
		{
			label: t`Ochrona danych osobowych`,
			description: t`Doradztwo we wdrożeniu zasad związanych z realizacją RODO w Multimedia Polska S.A.`,
			link: '/specjalizacje/ochrona-danych-osobowych'
		},
		{
			label: t`E-Commerce`,
			description: t`Ponad 10 regulaminów sprzedaży dla sklepów internetowych `,
			link: '/specjalizacje/e-commerce'
		},
		{
			label: t`Prawo konkurencji`,
			description: t`Skutecznie zapewniamy klientom obsługę prawną związaną z prawem ochrony konkurencji`,
			link: '/specjalizacje/prawo-konkurencji'
		},
		{
			label: t`IPO i Corporate Governance`,
			description: t`5 lat realizacji obowiązków informacyjnych spółki Multimedia Polska S.A. na Giełdę Papierów Wartościowych w Warszawie `,
			link: '/specjalizacje/ipo-i-corporate-governance'
		}
	];

	return (
		<LanguageProvider>
			<Layout>
				<main className="min-h-screen">
					<title>
						<Trans>Specjalizacje</Trans>
					</title>
					<div className="flex flex-col md:min-h-screen">
						<Header location={location.pathname} />
						<TitleBar>
							<div className="md:flex">
								<div className="relative flex-1 order-2">
									<div className="z-10 -mx-5 md:absolute md:ml-0 md:-mr-8 lg:-mr-10">
										<StaticImage
											className="block object-cover w-full h-60 md:h-96 lg:h-560 xl:h-500"
											placeholder={'none'}
											src={'../assets/images/specjalizacje-photo-01.jpg'}
											alt={t`Iwanejko-Taczkowska - Legal & Advisory - Specjalizacje`}
										/>
									</div>
								</div>
								<div className="pt-10 w-full max-w-lg text-white md:pr-20">
									<h1 className="mb-5 font-serif text-4xl font-bold">
										<Trans>Specjalizacje</Trans>
									</h1>
									<p>
										<Trans>
											Zdobyta wiedza i doświadczenie pozwalają nam świadczyć
											usługi w szerokim spectrum zagadnień prawnych.
										</Trans>
									</p>
								</div>
							</div>
						</TitleBar>

						<Container>
							<div className="grid relative z-20 gap-4 py-8 md:py-12 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
								{data.map((item) =>
									item?.label === '' ? (
										<div key={item.label} className="hidden lg:block" />
									) : (
										<div key={item.label}>
											<Specialization
												label={item.label}
												description={item.description}
												link={item.link}
											/>
										</div>
									)
								)}

								<div className="hidden lg:block">&nbsp;</div>
							</div>
						</Container>
						<Footer />
					</div>
				</main>
			</Layout>
		</LanguageProvider>
	);
};

export default SpecjalizacjePage;
