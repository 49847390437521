import { ArrowNarrowRightIcon } from '@heroicons/react/solid';
import { Trans } from '@lingui/macro';
import { Link } from 'gatsby';
import React from 'react';
import { FC } from 'react';

type SpecializationProps = {
	label: string;
	description?: string;
	link: string;
};

const Specialization: FC<SpecializationProps> = ({
	label,
	link,
	description
}) => {
	return (
		<Link
			className="flex relative flex-col p-8 h-full block transition duration-200 transform bg-gray-light text-gray-dark hover:bg-blue hover:z-50 hover:text-white hover:scale-105 hover:shadow-2xl group"
			to={link}
		>
			<h3 className="mb-5 text-lg font-bold">{label}</h3>
			<p className="mb-8 text-sm">{description}</p>
			<div className="flex justify-between mt-auto">
				<div className="font-bold uppercase">
					<Trans>Więcej</Trans>
				</div>
				<ArrowNarrowRightIcon className="w-6 h-6 transition duration-500 transform -translate-x-2 text-blue group-hover:text-white group-hover:translate-x-2" />
			</div>
		</Link>
	);
};

export default Specialization;
